<template>
      <div class="columns is-mobile is-centered">
        <div class="column is-half">
          <br><br><br>
          <div class="box">
            <h2>WELCOME TO SLATE!</h2>
            <br>
            <p>Click <icon icon="file-invoice" /> icon to view your estimate and sourcing requests. Thank you for being a key jansy vendor!</p>
            <br>
            <p>Please contact <a href="mailto:jyu@jansypkg-cn.com">jyu@jansypkg-cn.com</a> and <a href="mailto:helpdesk@jansypkg.com">helpdesk@jansypkg.com</a> for any technical issues.</p>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  components: {

  },
};
</script>

<style lang="scss">
</style>
